import { Heading, HeadingProps } from "@chakra-ui/react"

type Props = HeadingProps & {
  title: string
}

export const SectionTitle = ({
  title,
  as = "h2",
  fontWeight = "bold",
  fontSize = { base: "4xl", sm: "5xl", md: "6xl" },
  lineHeight = "1.25",
  mb = { base: "6", md: "8" },
  textTransform = "none",
  ...rest
}: Props) => {
  return (
    <Heading
      as={as}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      mb={mb}
      textTransform={textTransform}
      {...rest}
    >
      {title}
    </Heading>
  )
}
